import {
  Box,
  Text,
  RadioGroup,
  Stack,
  Radio,
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react'
import { useState } from 'react'
import { BsFillSquareFill } from 'react-icons/bs'

const BoardSettings = () => {
  const [value, setValue] = useState('public')
  const [brandColor, setBrandColor] = useState('#41B883')

  return (
    <Box mt="1.875rem">
      <Box
        display="flex"
        flexDirection={['column', 'column', 'row', 'row']}
        mt="1.875"
      >
        <Box display="flex" flexDirection="column">
          <Text color="#7F7F7F">Board Header Color</Text>
          <InputGroup
            width={['20.9rem', '20.9rem', '22.5rem', '22.5rem']}
            mt="0.625rem"
          >
            <InputLeftElement
              pointerEvents="none"
              children={<BsFillSquareFill color={brandColor} />}
            />
            <Input
              type="text"
              placeholder="#41B883"
              onChange={(e) => setBrandColor(e.target.value)}
            />
          </InputGroup>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          ml={['', '', '1.5rem', '1.5rem']}
          mt={['1.875rem', '1.875rem', '0', '0']}
        >
          <Text color="#7F7F7F">Board Visibility</Text>
          <RadioGroup
            onChange={setValue}
            value={value}
            mt={['0.625rem', '0.625rem', '1.25rem', '1.25rem']}
          >
            <Stack direction="row">
              <Radio value="public">Public</Radio>
              <Radio disabled value="private">
                Private (Coming soon)
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
      </Box>
    </Box>
  )
}

export default BoardSettings
