import { useState, useContext } from 'react'
import { Box, Textarea, VStack, Button, useToast } from '@chakra-ui/react'
import { IoBulbOutline } from 'react-icons/io5'

import { createCard } from 'src/api/consumer'
import { AppContext } from 'src/contexts/appContext'
import { getRoadMap } from 'src/api/consumer'

const FeatureRequestForm = () => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [creating, setCreating] = useState(false)

  const { setOrgData } = useContext(AppContext)
  const toast = useToast()

  const handleSubmit = async () => {
    setCreating(true)
    const response = await createCard(title, content)

    if (response.id) {
      setTitle('')
      setContent('')

      const roadmap = await getRoadMap()
      showToast('Created', 'success')
      setOrgData(roadmap)
    } else {
      showToast('Failed', 'error')
    }
    setCreating(false)
  }

  const showToast = (
    description: string,
    status: 'info' | 'warning' | 'success' | 'error',
  ) => {
    toast({
      description,
      status: status,
      variant: 'solid',
      position: 'top',
      duration: 2000,
    })
  }

  const isSubmitButtonDisabled = () => {
    const isEmpty = !title || !content
    const isSubmitting = creating
    const hasNoChars = title.trim() == '' || content.trim() == ''
    const isDisabled = isEmpty || isSubmitting || hasNoChars

    return isDisabled
  }

  return (
    <Box height="calc(100vh - 160px)">
      <Box mb="1.25rem" color="brand">
        <IoBulbOutline fontSize="2.5rem" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 160px)"
        justifyContent="space-between"
      >
        <form
          id="my-form"
          onSubmit={(e) => {
            e.preventDefault()
            console.log('submitted', e)
          }}
        >
          <Box fontSize="20px" fontWeight="500" lineHeight="24px">
            Add feature request
          </Box>
          <Box
            fontSize="14px"
            fontWeight="400"
            lineHeight="21px"
            color="#808080"
          >
            Your feature request will be reviewed by the team before publishing.
          </Box>
          <Box mt="30px">
            <VStack spacing={2}>
              <Textarea
                name="title"
                placeholder="What is the feature?"
                fontSize="14px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                height="76px"
              />
              <Textarea
                name="content"
                placeholder="Give us more details"
                fontSize="14px"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                height="200px"
              />
              <Button
                size="lg"
                type="submit"
                background="rgba(0, 0, 0, 0.3)"
                _hover={{
                  bg: 'brand',
                  color: 'white',
                }}
                w="100%"
                color="white"
                isLoading={creating}
                disabled={isSubmitButtonDisabled()}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </VStack>
          </Box>
        </form>
        <Box bottom="1rem" color="rgba(0, 0, 0, 0.3)" textAlign="center">
          Powered By
          <Box
            as="a"
            href="https://openroadmap.io"
            ml="0.25rem"
            textDecoration="underline"
          >
            openroadmap.io
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FeatureRequestForm
