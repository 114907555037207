import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  Link,
} from '@chakra-ui/react'
import { MdLogout } from 'react-icons/md'

type IProps = {
  isOpen: boolean
  onClose: () => void
}

const LogoutModal = ({ isOpen, onClose }: IProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton tabIndex={-1} />
        <ModalBody display="flex" flexDirection="column" py="3.125rem">
          <Box color="white" display="flex" justifyContent="center">
            <Box
              bg="brand"
              fontSize="1.25rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50px"
              width="50px"
              borderRadius="50%"
            >
              <MdLogout />
            </Box>
          </Box>
          <Text
            fontWeight="700"
            mt="1.125rem"
            textAlign="center"
            fontSize="1.5rem"
          >
            Logged Out Successfully!
          </Text>
          <Text textAlign="center" mt="15px" fontSize="1rem">
            To host your own roadmaps,{' '}
            <Link
              tabIndex={-1}
              textDecoration="underline"
              href={`${window.location.origin}/register`}
            >
              Sign up
            </Link>
            &nbsp;here
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LogoutModal
