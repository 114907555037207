import { Box, Select, Input } from '@chakra-ui/react'

const SelectTool = () => {
  return (
    <Box>
      <Box display="flex" flexDirection={['column', 'column', 'row', 'row']}>
        <Select
          placeholder="Select option"
          width={['20.9rem', '20.9rem', '22.5rem', '22.5rem']}
        >
          <option value="option1">Notion</option>
        </Select>
        <Input
          width={['20.9rem', '20.9rem', '22.5rem', '22.5rem']}
          ml={['', '', '1.5rem', '1.5rem']}
          mt={['1rem', '1rem', '0', '0']}
          placeholder="https://"
        />
      </Box>
    </Box>
  )
}

export default SelectTool
