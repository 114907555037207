import { HStack, Box, useRadio, useRadioGroup } from '@chakra-ui/react'
import { AiOutlineUnorderedList, AiOutlineAppstore } from 'react-icons/ai'

const CustomRadio = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" borderRadius="4px">
      <input {...input} />
      <Box
        {...checkbox}
        _checked={{
          backgroundColor: 'brand',
          color: 'white',
          margin: '5px',
          borderRadius: '4px',
        }}
        fontSize="12px"
        padding="5px"
      >
        {props.children}
      </Box>
    </Box>
  )
}

type IProps = {
  onChange: (value: string) => void
}

const LayoutToggle = ({ onChange }: IProps) => {
  const list = [
    {
      name: 'List View',
      value: 'list',
    },
    {
      name: 'Grid View',
      value: 'grid',
    },
  ]

  const handleToggle = (value: string) => {
    onChange(value)
  }

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'list',
    defaultValue: 'grid',
    onChange: handleToggle,
  })

  const group = getRootProps()
  return (
    <HStack {...group} cursor="pointer">
      <Box bg="white" display="flex" alignItems="center" borderRadius="4px">
        {list.map((item) => (
          <CustomRadio key={item} {...getRadioProps({ value: item.value })}>
            <Box
              display="flex"
              alignItems="center"
              cursor="pointer"
              borderRadius="4px"
            >
              {item.name === 'Grid View' ? (
                <AiOutlineAppstore />
              ) : (
                <AiOutlineUnorderedList />
              )}{' '}
              <Box ml="5px">{item.name}</Box>
            </Box>
          </CustomRadio>
        ))}
      </Box>
    </HStack>
  )
}

export default LayoutToggle
