import { useEffect, useState } from 'react'
import { extendTheme, Box, ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { getAuth, onAuthStateChanged } from 'firebase/auth'
import FeaturePage from 'src/pages/feature-page'
import LoginPage from 'src/pages/login'
import RegisterPage from 'src/pages/register'
import { AppProvider } from 'src/contexts/appContext'
import OrganizationDetails from 'src/components/organization-details'
import Projects from 'src/components/projects'
import CardsPage from 'src/pages/card-details'
import ProtectedRoute from 'src/components/protected-route'
import { defaultValue } from 'src/contexts/appContext'
import type { DefaultValueType } from 'src/contexts/appContext'
import { buttonStyles as Button } from 'src/utils/button-styles'
import { defaultOrgData } from 'src/contexts/appContext'

const App = () => {
  const [sharedData, setSharedData] = useState<DefaultValueType>(defaultValue)
  const [orgData, setOrgData] = useState(defaultOrgData)
  const [user, setUser] = useState({ doneFetching: true })

  const updateOrgMetaData = (orgMeta: any) => {
    setSharedData({ ...sharedData, org: { ...orgMeta } })
  }

  useEffect(() => {
    const auth = getAuth()

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser({ ...user, doneFetching: true })
      } else {
        setUser({ doneFetching: true })
      }
    })
  }, [])

  const colors = {
    primary: '#41B883',
    dark: '#05203D',
    brand: sharedData?.org?.project?.color,
  }

  const theme = extendTheme({
    colors,
    components: {
      Button,
    },
  })

  return (
    <AppProvider
      value={{ ...sharedData, updateOrgMetaData, user, orgData, setOrgData }}
    >
      <ChakraProvider theme={theme}>
        <BrowserRouter basename="/">
          <Box>
            <Switch>
              <Route
                path="/:organisation/:project"
                exact={true}
                component={FeaturePage}
              />
              <ProtectedRoute
                path="/projects"
                component={Projects}
                exact={true}
              />
              <Route
                path="/details"
                exact={true}
                component={OrganizationDetails}
              />
              <Route
                path="/:organisation/:project/cards/:id"
                exact={true}
                component={CardsPage}
              />
              <Route path="/register" exact={true} component={RegisterPage} />
              <Route
                path="/login"
                exact={true}
                component={() => <LoginPage location="page" />}
              />
              <Route path="*">
                <h1>Inccorect path</h1>
              </Route>
            </Switch>
          </Box>
        </BrowserRouter>
      </ChakraProvider>
    </AppProvider>
  )
}

export default App
