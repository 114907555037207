import { Text, Box, Divider, Button, useDisclosure } from '@chakra-ui/react'

import StepCount from 'src/components/projects/step-title'
import ProjectName from 'src/components/projects/steps/project-name'
import SelectTool from 'src/components/projects/steps/select-tool'
import BoardSettings from 'src/components/projects/steps/board-settings'
import ProjectCreatedModal from 'src/components/modals/project-created'

const CreateNewProject = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const steps = [
    {
      title: 'Project Name',
      stepCount: 1,
      isActive: true,
      component: ProjectName,
    },
    {
      title: 'Select Tool & Integrate',
      stepCount: 2,
      isActive: false,
      component: SelectTool,
    },
    {
      title: 'Board Settings',
      stepCount: 3,
      isActive: false,
      component: BoardSettings,
    },
  ]

  const createProject = () => {
    onOpen()
  }

  return (
    <>
      <Text fontSize="1.75rem" fontWeight="600">
        {' '}
        Create a new project
      </Text>
      <Text fontSize="1rem" mt="0.75rem">
        Track progress of your project and get feedback
      </Text>
      {steps.map((step) => (
        <>
          <Box mt="3.25rem">
            <StepCount
              title={step.title}
              stepCount={step.stepCount}
              isActive={step.isActive}
            />
            <Divider mt="1.25rem" width="90%" />
          </Box>
          <Box mt="0.75rem">{<step.component />}</Box>
        </>
      ))}
      <Button onClick={createProject} mt="5.25rem">
        Create Project
      </Button>
      <ProjectCreatedModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default CreateNewProject
