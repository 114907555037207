const getIconColor = (type: string | undefined) => {
  switch (type) {
    case 'Not started':
      return '#E20A16'
    case 'In progress':
      return '#E28C0A'
    case 'Completed':
      return '#41B883'
    case 'Removed':
      return '#000000'
    default:
      return '#000000'
  }
}

export default getIconColor
