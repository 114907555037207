import uniq from 'lodash.uniq'

import type { CardProps } from 'src/types/card'

export const getUniqueColumns = (cards: CardProps[]) => {
  let columns = cards?.map((card) => (card.status ? card.status : 'No Status'))
  columns = uniq(columns)

  return columns
}

export const getCards = (type: string, cards: CardProps[]) => {
  const filteredCards = cards.filter((card) => {
    if (card.status === type) return true

    if (type == 'No Status' && !card.status) return true
  })

  return filteredCards
}
