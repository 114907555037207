import { createContext } from 'react'
import type { CardProps } from 'src/types/card'

type OrgProps = {
  token?: string
  project?: {
    _id?: string
    name?: string
    created_at?: Date
    color: string
    organization: {
      _id?: string
      name?: string
    }
  }
}

export const defaultOrgData = {
  cards: [
    {
      id: '',
      title: '',
      created_time: new Date(),
      icon: '',
      cover: '',
      html: '',
      assign: '',
      rank: '',
      votes: 0,
      targeted_version: '',
      status: '',
    },
  ],
  title: '',
}

type OrgDataProps = {
  cards: CardProps[]
  title: string
}

export type DefaultValueType = {
  org: OrgProps
  orgData: OrgDataProps
  setOrgData: (orgData: any) => void
  updateOrgMetaData: (org: any) => void
  user: { doneFetching: boolean }
}

export const defaultValue: DefaultValueType = {
  org: {
    project: {
      color: '',
      organization: {},
    },
  },
  orgData: defaultOrgData,
  updateOrgMetaData: () => {},
  setOrgData: () => {},
  user: { doneFetching: false },
}

const AppContext = createContext(defaultValue)
AppContext.displayName = 'App page'

const AppProvider = AppContext.Provider
const AppConsumer = AppContext.Consumer

export { AppProvider, AppConsumer, AppContext }
