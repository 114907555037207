import getBaseURL from 'src/utils/get-base-url'

export const getToken = async (
  organisationSlug: string,
  projectSlug: string,
) => {
  const url = `${getBaseURL()}/api/auth/${organisationSlug}/${projectSlug}`
  const response = await fetch(url)
  const data = await response.json()

  return data
}
