import { useLayoutEffect, useContext, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'

import { AppContext } from 'src/contexts/appContext'
import type { ProtectedRouteType } from 'src/types/protected-route'

const ProtectedRoute = ({
  component: Component,
  ...rest
}: ProtectedRouteType) => {
  const history = useHistory()
  const { user } = useContext(AppContext)
  const invalidUser = Object.keys(user).length == 1
  const [hasPageLoaded, setPageLoad] = useState(false)

  useLayoutEffect(() => {
    if (invalidUser && user.doneFetching) {
      history.push('/login')
    } else {
      setPageLoad(true)
    }
  }, [user])

  const renderComponent = () => {
    return <Component />
  }

  return hasPageLoaded ? (
    <Route {...rest} render={() => renderComponent()} />
  ) : (
    <></>
  )
}

export default ProtectedRoute
