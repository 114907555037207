import getBaseURL from 'src/utils/get-base-url'

export const getRoadMap = async () => {
  const url = `${getBaseURL()}/api/roadmap`
  const token: any = localStorage.getItem('orm-token')

  const response = await fetch(url, {
    headers: {
      'x-orm-token': token,
    },
  })

  const data = await response.json()
  return data
}

export const upVoteCard = async (id: string, gAuth: string) => {
  const url = `${getBaseURL()}/api/roadmap/card/${id}`
  const token: any = localStorage.getItem('orm-token')

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'x-orm-token': token,
      Authorization: `Bearer ${gAuth}`,
    },
  })

  const data = await response.json()
  return data
}

export const getCard = async (id: string) => {
  const url = `${getBaseURL()}/api/roadmap/card/${id}`
  const token: any = localStorage.getItem('orm-token')

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'x-orm-token': token,
    },
  })

  const data = await response.json()
  return data
}

export const createCard = async (title: string, content: string) => {
  const url = `${getBaseURL()}/api/roadmap/card`
  const token: any = localStorage.getItem('orm-token')

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'x-orm-token': token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title,
      content,
    }),
  })

  const data = await response.json()

  return data
}
