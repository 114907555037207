import { Box, Text } from '@chakra-ui/react'
import { AiOutlineArrowDown } from 'react-icons/ai'

const Demo = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={['18.875rem', '18.875rem', '18.875rem', '36rem']}
    >
      <Text display={['block', 'block', 'block', 'none']} fontSize="18px">
        <Box as="img" display="inline-block" src="assets/logo.png" />
        pen Roadmap
      </Text>
      <Text
        display={['block', 'block', 'block', 'none']}
        mt={['1.25rem', '1.25rem', '1.875rem', '1.875rem']}
        fontWeight="600"
        fontSize="1.375rem"
      >
        Enter Organization Details
      </Text>
      <Text
        display={['flex', 'flex', 'flex', 'none']}
        alignItems="center"
        mt="1rem"
        fontSize="14px"
      >
        See how it will be used &nbsp; <AiOutlineArrowDown />
      </Text>
      <Box as="img" src="assets/org-demo.png" />
    </Box>
  )
}

export default Demo
