import { Box, Text, Stack, Divider, useColorModeValue } from '@chakra-ui/react'
import { AiOutlineUser } from 'react-icons/ai'
import { MouseEvent } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import type { CardProps, URLTypes } from 'src/types/card'
import VoteButton from 'src/components/vote-button'

type IProps = {
  card: CardProps
  openAuthModal: () => void
  index: number
}

const FeatureCard = ({ card, openAuthModal, index }: IProps) => {
  const { title, assign, id } = card
  const history = useHistory()
  const { organisation, project }: URLTypes = useParams()

  const handleCardClick = (e: MouseEvent) => {
    const { type, tagName } = e.target as HTMLInputElement

    // Don't show the card detail menu on button click
    if (type !== 'button' && ['path', 'svg'].indexOf(tagName) === -1) {
      history.push(`/${organisation}/${project}/cards/${id}`)
    }
  }

  return (
    <Box
      w="264px"
      key={index}
      bg={useColorModeValue('white', 'gray.800')}
      cursor="pointer"
      rounded={'md'}
      onClick={handleCardClick}
      p="1.25rem"
      mb="15px"
      overflow={'hidden'}
    >
      <Stack spacing="15px">
        <Text fontSize="16px" fontWeight={500} p={2}>
          {title}
        </Text>
        <Divider />
        {assign && (
          <Text
            fontSize="12px"
            color="#808080"
            display="flex"
            alignItems="center"
          >
            <AiOutlineUser />
            &nbsp; {assign}
          </Text>
        )}
      </Stack>
      <VoteButton card={card} openAuthModal={openAuthModal}></VoteButton>
    </Box>
  )
}

export default FeatureCard
