import { useState, useContext } from 'react'
import { Box, Text, useDisclosure, Button } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'

import Panel from 'src/components/top-bar'
import Sidebar from 'src/components/sidebar'
import LayoutToggle from 'src/components/layouts/layout-toggle'
import MobileSidebar from 'src/components/sidebar/mobile-drawer'
import { AppContext } from 'src/contexts/appContext'

type IProps = {
  sidebarContent: JSX.Element
  gridViewContent: JSX.Element
  listViewContent: JSX.Element
}

const FullWidthLayout = ({
  sidebarContent,
  gridViewContent,
  listViewContent,
}: IProps) => {
  const [isCollapsed, setCollapse] = useState(false)
  const [layout, setLayout] = useState('grid')
  const { org } = useContext(AppContext)

  const {
    isOpen: isMobileSidebarOpen,
    onOpen: openMobileSidebar,
    onClose: closeMobileSidebar,
  } = useDisclosure()

  const AddRequestButton = () => {
    return (
      <Box
        position="fixed"
        width="100%"
        bottom="2rem"
        display={['', '', 'none', 'none']}
      >
        <Button
          leftIcon={<AiOutlinePlus color="#41B883" />}
          borderRadius="50px"
          m="0px auto"
          boxShadow="lg"
          bg="white"
          _hover={{ bg: 'white' }}
          display="block"
          onClick={openMobileSidebar}
        >
          Add Feature Request
        </Button>
      </Box>
    )
  }

  return (
    <>
      <Panel />
      <Box display="flex" overflow="hidden">
        <Box>
          <Sidebar isCollapsed={isCollapsed} setCollapse={setCollapse}>
            {sidebarContent}
          </Sidebar>
          <MobileSidebar
            isOpen={isMobileSidebarOpen}
            onClose={closeMobileSidebar}
          >
            {sidebarContent}
          </MobileSidebar>
        </Box>
        <Box
          bg="#E5E5E5"
          width="full"
          px={isCollapsed ? '4.875rem' : '1.5rem'}
          py="1.5rem"
          overflowX="scroll"
          height={'calc(100vh - 50px)'}
        >
          <Box
            display="flex"
            alignItems={['', '', 'center', 'center']}
            mb="2.5rem"
            flexDirection={['column', 'column', 'row', 'row']}
          >
            <Text
              display="flex"
              alignItems="center"
              fontWeight="500"
              fontSize="1.25rem"
            >
              {org.project?.name}
            </Text>
            <Box
              ml={['0', '0', '1.25rem', '1.25rem']}
              mt={['1.25rem', '1.25rem', '0', '0']}
            >
              <LayoutToggle onChange={(value) => setLayout(value)} />
            </Box>
          </Box>
          {/* Main view area */}
          <Box display="flex">
            {layout === 'grid' ? gridViewContent : listViewContent}
          </Box>
        </Box>
        <AddRequestButton />
      </Box>
    </>
  )
}

export default FullWidthLayout
