import { Fragment } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { GoPrimitiveDot } from 'react-icons/go'

import FeatureCard from 'src/components/feature/feature-card'
import type { CardProps } from 'src/types/card'
import getIconColor from 'src/utils/get-icon-color'

type IProps = {
  text: string
  cards: CardProps[]
  index: number
  openAuthModal: () => void
}

const FeatureColumn = ({ text, cards, openAuthModal, index }: IProps) => {
  return (
    <Box width="264px" mr="24px" key={index}>
      <Box display="flex" justifyContent="space-between">
        <Text
          fontSize="14px"
          color="#808080"
          mb="15px"
          display="flex"
          alignItems="center"
        >
          <GoPrimitiveDot color={getIconColor(text)} /> &nbsp; {text}
        </Text>
        <Text fontSize="14px" color="#808080">
          ({cards.length})
        </Text>
      </Box>
      {cards.map((card, index) => (
        <Fragment key={index}>
          <FeatureCard
            index={index}
            card={card}
            openAuthModal={openAuthModal}
          />
        </Fragment>
      ))}
    </Box>
  )
}

export default FeatureColumn
