import { whiten } from '@chakra-ui/theme-tools'

export const buttonStyles = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: {
      bg: 'primary',
      color: 'white',
      _hover: {
        bg: whiten('primary', 20),
      },
    },
    dark: {
      bg: 'dark',
      color: 'white',
      _hover: {
        bg: whiten('dark', 20),
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
}
