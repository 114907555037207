import { Menu, Button, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { AiOutlineDown, AiOutlineUser } from 'react-icons/ai'
import { MdLogout } from 'react-icons/md'
import { RiHome2Line } from 'react-icons/ri'

import { userLogout } from 'src/firebase/firebase'

type Props = {
  onOpen: () => void
}

const LoggedInMenu = ({ onOpen }: Props) => {
  const handleMenuClick = (value: string) => {
    switch (value) {
      case 'myProjects':
        break
      case 'logout':
        userLogout()
        onOpen()

        break
      default:
        break
    }
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<AiOutlineDown />}
        variant="dark"
        size="sm"
      >
        <AiOutlineUser />
      </MenuButton>
      <MenuList bg="dark">
        <MenuItem
          onClick={() => handleMenuClick('myProjects')}
          _hover={{ bg: 'none' }}
          _focus={{ bg: 'dark' }}
        >
          <RiHome2Line /> &nbsp;My projects
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuClick('logout')}
          _hover={{ bg: 'none' }}
        >
          <MdLogout />
          &nbsp;Log Out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default LoggedInMenu
