import { Box, Divider } from '@chakra-ui/react'

import Details from 'src/components/organization-details/details'
import Demo from 'src/components/organization-details/demo'

const OrganizationDetails = () => {
  return (
    <Box
      bg='url("assets/login-background.png")'
      height="100vh"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <Box
        display="flex"
        height="inherit"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          padding={['1.25rem', '1.25rem', '1.25rem', '3.25rem']}
          flexDirection={[
            'column-reverse',
            'column-reverse',
            'column-reverse',
            'row',
          ]}
          bg="white"
          borderRadius="30px"
          width={['20.9375rem', '20.9375rem', '20.9375rem', '61.75rem']}
        >
          <Details />
          <Box ml="3.25rem">
            <Divider orientation="vertical" />
          </Box>
          <Demo />
        </Box>
      </Box>
    </Box>
  )
}

export default OrganizationDetails
