import { useState } from 'react'
import {
  Box,
  Text,
  Input,
  Button,
  VStack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
import { BsFillSquareFill } from 'react-icons/bs'

const Details = () => {
  const [brandColor, setBrandColor] = useState('#41B883')

  return (
    <Box width={['', '', 'none', '25.625rem']}>
      <Text display={['none', 'none', 'none', 'block']} fontS ize="18px">
        <Box as="img" display="inline-block" src="assets/logo.png" />
        pen Roadmap
      </Text>
      <Text
        mt="1.875rem"
        display={['none', 'none', 'none', 'block']}
        fontWeight="600"
        fontSize="1.5rem"
      >
        Enter Organization Details
      </Text>
      <VStack spacing="1.25rem" mt="1.875rem">
        <Input placeholder="Organization Name" size="lg" fontSize="0.875rem" />
        <Input
          placeholder="Organization Logo URL (Optional)"
          size="lg"
          fontSize="0.875rem"
        />
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<BsFillSquareFill color={brandColor} />}
          />
          <Input
            type="text"
            placeholder="#41B883"
            onChange={(e) => setBrandColor(e.target.value)}
          />
        </InputGroup>
        <Button
          type="submit"
          fontSize="0.875rem"
          size="lg"
          background="rgba(0, 0, 0, 0.3)"
          _hover={{
            bg: 'primary',
            color: 'white',
          }}
          w="100%"
          color="white"
        >
          Create
        </Button>
      </VStack>
    </Box>
  )
}

export default Details
