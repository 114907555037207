import { useState, useContext, useEffect } from 'react'
import { Button, Icon, useToast } from '@chakra-ui/react'
import { AiOutlineArrowUp } from 'react-icons/ai'

import { CardProps } from 'src/types/card'
import { upVoteCard } from 'src/api/consumer'
import { AppContext } from 'src/contexts/appContext'
import { isValidUser } from 'src/utils/is-valid-user'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

type VoteButtonProps = {
  card: CardProps
  openAuthModal: () => void
  type?: string
}

const VoteButton = ({ card, openAuthModal, type }: VoteButtonProps) => {
  const [voting, setVoting] = useState(false)
  const [voteFlag, setVoteFlag] = useState(false)

  const toast = useToast()
  const { user }: any = useContext(AppContext)

  useEffect(() => {
    const auth = getAuth()

    onAuthStateChanged(auth, async (userAuth: any) => {
      if (userAuth && voteFlag) {
        // https://firebase.google.com/docs/reference/js/firebase.User
        setVoting(true)
        const data = await upVoteCard(card.id, userAuth.accessToken)

        if (data?.Votes > 0) {
          card.votes = data.Votes
          showToast('Voted', 'success')
        } else {
          showToast('Failed', 'error')
        }
        setVoting(false)
        setVoteFlag(false)
      }
    })
  }, [voteFlag])

  const showToast = (
    description: string,
    status: 'info' | 'warning' | 'success' | 'error',
  ) => {
    toast({
      description,
      status: status,
      variant: 'solid',
      position: 'top',
      duration: 2000,
    })
  }

  const vote = async (id: string) => {
    if (isValidUser(user)) {
      try {
        setVoting(true)
        const data = await upVoteCard(id, user.accessToken)

        if (data?.Votes > 0) {
          card.votes = data.Votes
          showToast('Voted', 'success')
        } else {
          showToast('Failed', 'error')
        }
        setVoting(false)
      } catch (err) {
        setVoting(false)
      }
    } else {
      await openAuthModal()
      setVoteFlag(true)
    }
  }

  return type == 'sm' ? (
    <Button
      onClick={() => vote(card.id)}
      borderRadius="50px"
      variant="outline"
      p="1rem"
      _hover={{
        bg: 'brand',
        color: 'white',
      }}
      display="flex"
      color="brand"
      flexDirection="column"
      fontSize="0.75rem"
      disabled={voting}
      isLoading={voting}
      size="xs"
    >
      <Icon as={AiOutlineArrowUp} />
      {card.votes}
    </Button>
  ) : (
    <Button
      onClick={() => vote(card.id)}
      variant="outline"
      mt="15px"
      color="brand"
      _hover={{
        bg: 'brand',
        color: 'white',
      }}
      borderRadius="50px"
      size="sm"
      disabled={voting}
      isLoading={voting}
      rightIcon={<AiOutlineArrowUp />}
    >
      {card.votes} Upvotes
    </Button>
  )
}

export default VoteButton
