import {
  Drawer,
  DrawerBody,
  DrawerContent,
  Button,
  Box,
} from '@chakra-ui/react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

type IProps = {
  isOpen: boolean
  onClose: () => void
  children: JSX.Element
}

const MobileSidebar = ({ isOpen, onClose, children }: IProps) => {
  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="full">
        <DrawerContent>
          <DrawerBody>
            <Button
              marginTop="1.25rem"
              tabIndex={-1}
              onClick={onClose}
              variant="link"
              leftIcon={<AiOutlineArrowLeft />}
            >
              Go Back
            </Button>
            <Box marginTop="1.25rem">{children}</Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
export default MobileSidebar
