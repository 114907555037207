import { useState } from 'react'
import { Input, Box, FormControl, FormHelperText, Text } from '@chakra-ui/react'
import { AiOutlineClose } from 'react-icons/ai'

const ProjectName = () => {
  const [isProjectNameValid] = useState(true)

  return (
    <Box>
      <FormControl width={['20.9rem', '20.9rem', '22.5rem', '22.5rem']}>
        <Input id="email" type="email" />
        {isProjectNameValid && (
          <FormHelperText display="flex" alignItems="center">
            <AiOutlineClose color="#e20a15" />
            &nbsp;Ringi Is <Text color="#e20a15">&nbsp; Not Available.</Text>
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  )
}

export default ProjectName
