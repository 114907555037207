import { useState } from 'react'
import {
  Box,
  Text,
  Button,
  VStack,
  Divider,
  Input,
  Link,
} from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'
import { VscGithub } from 'react-icons/vsc'

import { googleSignin, githubSignin } from 'src/firebase/firebase'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = () => {
    console.log('button clicked')
  }

  return (
    <Box
      bg='url("assets/login-background.png")'
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      height="100vh"
    >
      <Box
        display="flex"
        height="inherit"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          bg="white"
          padding={['1.25rem', '1.25rem', '3.25rem', '3.25rem']}
          borderRadius="30px"
          width={['20.875rem', '20.875rem', '25.625rem', '25.625rem']}
        >
          <Box display="flex">
            <Text fontSize="18px">
              <Box as="img" display="inline-block" src="assets/logo.png" />
              pen Roadmap
            </Text>
          </Box>
          <Text
            mt={['1.25rem', '1.25rem', '1.875rem', '1.875rem']}
            fontWeight="600"
            fontSize={['1.375rem', '1.375rem', '1.5rem', '1.5rem']}
          >
            Sign Up to Open Roadmap
          </Text>
          <VStack spacing="1rem" mt="1.25rem">
            <Button
              width="100%"
              tabIndex={-1}
              _hover={{
                bg: '#F7F7F7',
              }}
              fontSize="0.875rem"
              bg="#F7F7F7"
              size="lg"
              onClick={googleSignin}
              leftIcon={<FcGoogle />}
            >
              Continue With Google
            </Button>
            <Button
              tabIndex={-1}
              width="100%"
              onClick={githubSignin}
              fontSize="0.875rem"
              _hover={{
                bg: 'black',
              }}
              bg="black"
              size="lg"
              color="white"
              leftIcon={<VscGithub />}
            >
              Continue With GitHub
            </Button>
          </VStack>
          <Box
            mt="30px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Divider />
            <Text px="0.5rem" fontSize="14px">
              Or
            </Text>
            <Divider />
          </Box>
          <Box mt="1.875rem">
            <VStack spacing="1rem" mt="1.25rem">
              <Input
                fontSize="0.875rem"
                type="text"
                size="lg"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                fontSize="0.875rem"
                type="text"
                size="lg"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                onClick={handleSubmit}
                size="lg"
                type="submit"
                background="rgba(0, 0, 0, 0.3)"
                _hover={{
                  bg: 'primary',
                  color: 'white',
                }}
                fontSize="0.875rem"
                w="100%"
                color="white"
              >
                Submit
              </Button>
              <Text
                textAlign="center"
                color="rgba(0, 0, 0, 0.3);"
                mt="15px"
                fontSize="1rem"
              >
                Already have an account?{' '}
                <Link
                  tabIndex={-1}
                  textDecoration="underline"
                  href={`${window.location.origin}/login`}
                >
                  Sign In
                </Link>
              </Text>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Register
