import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  InputGroup,
  Input,
  InputLeftElement,
  IconButton,
} from '@chakra-ui/react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FiCopy } from 'react-icons/fi'

type IProps = {
  isOpen: boolean
  onClose: () => void
}

const ProjectCreatedModal = ({ isOpen, onClose }: IProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody display="flex" flexDirection="column" py="3.125rem">
          <Box display="flex" alignItems="center">
            <Box height="30px" width="30px" color="brand" fontSize="1.875rem">
              <AiFillCheckCircle />
            </Box>
            <Text
              ml="0.875rem"
              fontSize="1.5rem"
              fontWeight="600"
              lineHeight="2.25rem"
            >
              Project Created
            </Text>
          </Box>
          <Text color="#05203D" fontSize="0.875rem">
            Share it with your friends & community
          </Text>
          <InputGroup size="md" mt="1rem">
            <InputLeftElement width="4.5rem" ml="-1.25rem">
              <IconButton aria-label="copy" icon={<FiCopy />} />
            </InputLeftElement>
            <Input type="text" value="openroadmap.io/quoppo/Ringisho" />
          </InputGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ProjectCreatedModal
