import { Fragment, useContext } from 'react'
import { Box, useDisclosure, Spinner } from '@chakra-ui/react'

import FeatureColumn from 'src/components/feature/feature-column'
import LoginModel from 'src/components/modals/login-modal'
import { AppContext } from 'src/contexts/appContext'
import { getCards, getUniqueColumns } from 'src/components/views/utils'
import { isValidUser } from 'src/utils/is-valid-user'

const FeatureContent = () => {
  const {
    isOpen: isAuthModalVisible,
    onOpen: openAuthModal,
    onClose: closeAuthModal,
  } = useDisclosure()
  const { user, orgData } = useContext(AppContext)

  const columns = getUniqueColumns(orgData['cards'])

  const Content = (
    <>
      <Box display="flex" width="inherit">
        {columns.map((column, index) => (
          <Fragment key={index}>
            <FeatureColumn
              index={index}
              openAuthModal={openAuthModal}
              text={column}
              cards={getCards(column, orgData['cards'])}
            />
          </Fragment>
        ))}
      </Box>
      {!isValidUser(user) && (
        <LoginModel
          isAuthModalVisible={isAuthModalVisible}
          closeAuthModal={closeAuthModal}
        />
      )}
    </>
  )

  const Loading = (
    <Box
      mx="auto"
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="100vh"
      textAlign="center"
      h="70vh"
    >
      <Spinner color="brand" />
    </Box>
  )

  return orgData['cards']?.length > 1 ? Content : Loading
}

export default FeatureContent
