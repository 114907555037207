import { useContext } from 'react'
import { Box, Text, useDisclosure } from '@chakra-ui/react'

import { AppContext } from 'src/contexts/appContext'
import LoggedInMenu from 'src/components/top-bar/logged-in-menu'
import LogoutModal from 'src/components/modals/logout'
import { isValidUser } from 'src/utils/is-valid-user'

type IProps = {
  type?: string
}

const Panel = ({ type }: IProps) => {
  const { user } = useContext(AppContext)
  const { org } = useContext(AppContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const getTitle = () => {
    if (!org?.project?.organization?.name) {
      return 'Open roadmap'
    }

    return `${org?.project?.organization.name} / ${org?.project?.name}`
  }

  return (
    <>
      <Box
        px="1rem"
        height="50px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="white"
        position="sticky"
        top="0px"
        zIndex="999"
        bg={type == 'projects' ? 'primary' : 'brand'}
      >
        <Text fontSize="14px" fontWeight="500">
          {getTitle()}
        </Text>
        {isValidUser(user) && <LoggedInMenu onOpen={onOpen} />}
      </Box>
      <LogoutModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Panel
