import { useContext } from 'react'
import {
  Box,
  Text,
  Divider,
  useDisclosure,
  HStack,
  Spinner,
} from '@chakra-ui/react'
import { AiOutlineUser, AiOutlineUnorderedList } from 'react-icons/ai'
import { useHistory, useParams } from 'react-router-dom'
import { GoPrimitiveDot } from 'react-icons/go'

import type { CardProps, URLTypes } from 'src/types/card'
import LoginModel from 'src/components/modals/login-modal'
import { AppContext } from 'src/contexts/appContext'
import getIconColor from 'src/utils/get-icon-color'
import { getCards, getUniqueColumns } from 'src/components/views/utils'
import { isValidUser } from 'src/utils/is-valid-user'
import VoteButton from 'src/components/vote-button'

const ListViewContent = () => {
  const {
    isOpen: isAuthModalVisible,
    onOpen: openAuthModal,
    onClose: closeAuthModal,
  } = useDisclosure()
  const { user, orgData }: any = useContext(AppContext)
  const history = useHistory()
  const { organisation, project }: URLTypes = useParams()

  const handleListClick = ({ id }: CardProps) => {
    history.push(`/${organisation}/${project}/cards/${id}`)
  }

  const columns = getUniqueColumns(orgData['cards'])

  const renderColumnByStatus = () => {
    return columns.map((column, index) => (
      <Box mb="2.875rem" key={index}>
        <Box display="flex" justifyContent="space-between">
          <Text
            fontSize="14px"
            color="#808080"
            mb="15px"
            display="flex"
            alignItems="center"
          >
            <GoPrimitiveDot color={getIconColor(column)} /> &nbsp; {column}
          </Text>
          <Text fontSize="14px" color="#808080">
            ({getCards(column, orgData['cards']).length})
          </Text>
        </Box>
        <Box bg="white" rounded="md" px="1rem">
          {getCards(column, orgData['cards']).map((card, index) => (
            <Box key={index}>
              <Box display="flex" py="1rem">
                <VoteButton
                  openAuthModal={openAuthModal}
                  card={card}
                  type="sm"
                />
                <Box
                  ml="0.5rem"
                  onClick={() => handleListClick(card)}
                  cursor="pointer"
                >
                  <Text fontSize="14px" fontWeight="500">
                    {card.title}
                  </Text>
                  <HStack mt="0.75rem" spacing="0.75rem">
                    {card.targeted_version && (
                      <Text
                        fontSize="12px"
                        color="#808080"
                        display="flex"
                        alignItems="center"
                      >
                        <AiOutlineUnorderedList />
                        &nbsp; {card.targeted_version}
                      </Text>
                    )}
                    {card.assign && (
                      <Text
                        fontSize="12px"
                        color="#808080"
                        display="flex"
                        alignItems="center"
                      >
                        <AiOutlineUser />
                        &nbsp; {card.assign}
                      </Text>
                    )}
                  </HStack>
                  <Text fontSize="0.75rem" mt="10px">
                    {card.html}
                  </Text>
                </Box>
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      </Box>
    ))
  }

  const Content = (
    <>
      <Box w="100%">{renderColumnByStatus()}</Box>
      {!isValidUser(user) && (
        <LoginModel
          isAuthModalVisible={isAuthModalVisible}
          closeAuthModal={closeAuthModal}
        />
      )}
    </>
  )

  const Loading = (
    <Box
      mx="auto"
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="100vh"
      textAlign="center"
      h="70vh"
    >
      <Spinner color="brand" />
    </Box>
  )

  return orgData['cards']?.length > 1 ? Content : Loading
}

export default ListViewContent
