import { useContext, useState, useEffect } from 'react'
import {
  Box,
  Text,
  Button,
  HStack,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'
import { AiOutlineUser } from 'react-icons/ai'
import { useParams, useHistory } from 'react-router-dom'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { GoPrimitiveDot } from 'react-icons/go'

import Navbar from 'src/components/top-bar'
import type { CardProps, URLTypes } from 'src/types/card'
import LoginModel from 'src/components/modals/login-modal'
import { AppContext } from 'src/contexts/appContext'
import getIconColor from 'src/utils/get-icon-color'
import { getCard } from 'src/api/consumer'
import { getToken } from 'src/api/get-token'
import createMarkup from 'src/utils/create-markup'
import { isValidUser } from 'src/utils/is-valid-user'
import VoteButton from 'src/components/vote-button'
import { setMetaDescription, setTitle } from 'src/utils/set-page-data'

type IProps = {
  id: string
}

const CardDetails = () => {
  const [card, setCard] = useState<CardProps>()
  const [loading, setLoading] = useState(false)

  const { id }: IProps = useParams()
  const history = useHistory()
  const { user, updateOrgMetaData }: any = useContext(AppContext)

  const {
    isOpen: isAuthModalVisible,
    onOpen: openAuthModal,
    onClose: closeAuthModal,
  } = useDisclosure()

  const { organisation, project }: URLTypes = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const org = await getToken(organisation, project)
      localStorage.setItem('orm-token', org['token'])
      updateOrgMetaData(org)

      setTitle(`Open roadmap | ${org.project.name}`)
      setMetaDescription(`Roadmap for ${org.project.name}`)

      const filterCard = await getCard(id)
      setCard(filterCard)

      setLoading(false)
    }

    fetchData()
  }, [])

  const content = (
    <>
      <Navbar />
      <Box py="3.125rem" px="4rem" bg="#E5E5E5" height="calc(100vh - 50px)">
        <Button
          variant="link"
          color="black"
          leftIcon={<AiOutlineArrowLeft />}
          onClick={() => history.push(`/${organisation}/${project}`)}
        >
          Back to all features
        </Button>
        <Box spacing="24px" mt="2rem" bg="white" rounded="md" p="1.875rem">
          <Box
            display="flex"
            alignItems="center"
            color="#808080"
            fontSize="14px"
          >
            <GoPrimitiveDot color={getIconColor(card?.status)} /> &nbsp;
            {card?.status || 'No Status'}
          </Box>
          <Box display="flex" justifyContent="space-between" mt="1.125rem">
            <Text fontSize="24px" fontWeight={600} mb="1rem">
              {card?.title}
            </Text>
            {card && <VoteButton card={card} openAuthModal={openAuthModal} />}
          </Box>
          <HStack>
            {card?.assign && (
              <Text
                fontSize="12px"
                color="#808080"
                display="flex"
                alignItems="center"
              >
                <AiOutlineUser />
                &nbsp;
              </Text>
            )}
          </HStack>
          <Box
            mt="24px"
            dangerouslySetInnerHTML={createMarkup(card?.html || '')}
          />
        </Box>
      </Box>
      {!isValidUser(user) && (
        <LoginModel
          isAuthModalVisible={isAuthModalVisible}
          closeAuthModal={closeAuthModal}
        />
      )}
    </>
  )

  const Loading = (
    <Box
      mx="auto"
      d="flex"
      justifyContent="center"
      alignItems="center"
      w="100vh"
      textAlign="center"
      h="70vh"
    >
      <Spinner color="brand" />
    </Box>
  )

  return loading ? (
    <>
      <Navbar />
      {Loading}
    </>
  ) : (
    content
  )
}

export default CardDetails
