import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
  VStack,
  Box,
  Divider,
  Input,
} from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'
import { VscGithub } from 'react-icons/vsc'

import { googleSignin, githubSignin } from 'src/firebase/firebase'

type IProps = {
  isAuthModalVisible: boolean
  closeAuthModal: () => void
}

const LoginModal = ({ isAuthModalVisible, closeAuthModal }: IProps) => {
  const handleGoogleSignIn = () => {
    googleSignin()
    closeAuthModal()
  }

  const handleGithubSignIn = () => {
    githubSignin()
    closeAuthModal()
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = () => {
    console.log('button clicked')
  }

  return (
    <Modal isOpen={isAuthModalVisible} onClose={closeAuthModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody px="" p="1.2rem">
          <Box
            bg="white"
            padding={['1.25rem', '1.25rem', '3.25rem', '3.25rem']}
            borderRadius="30px"
          >
            <Box display="flex">
              <Text fontSize="18px">
                <Box as="img" display="inline-block" src="/assets/logo.png" />
                pen Roadmap
              </Text>
            </Box>
            <Text
              mt={['1.25rem', '1.25rem', '1.875rem', '1.875rem']}
              fontWeight="600"
              fontSize={['1.375rem', '1.375rem', '1.5rem', '1.5rem']}
            >
              Login to your
            </Text>
            <Text
              fontWeight="600"
              fontSize={['1.375rem', '1.375rem', '1.5rem', '1.5rem']}
            >
              Open Roadmap Account
            </Text>
            <VStack spacing="1rem" mt="1.25rem">
              <Button
                width="100%"
                tabIndex={-1}
                _hover={{
                  bg: '#F7F7F7',
                }}
                fontSize="0.875rem"
                bg="#F7F7F7"
                size="lg"
                onClick={handleGoogleSignIn}
                leftIcon={<FcGoogle />}
              >
                Continue With Google
              </Button>
              <Button
                tabIndex={-1}
                width="100%"
                onClick={handleGithubSignIn}
                fontSize="0.875rem"
                _hover={{
                  bg: 'black',
                }}
                bg="black"
                size="lg"
                color="white"
                leftIcon={<VscGithub />}
              >
                Continue With GitHub
              </Button>
            </VStack>
            <Box
              mt="30px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Divider />
              <Text px="0.5rem" fontSize="14px">
                Or
              </Text>
              <Divider />
            </Box>
            <Box mt="1.875rem">
              <VStack spacing="1rem" mt="1.25rem">
                <Input
                  type="text"
                  fontSize="0.875rem"
                  size="lg"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  type="password"
                  fontSize="0.875rem"
                  size="lg"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  onClick={handleSubmit}
                  size="lg"
                  type="submit"
                  background="rgba(0, 0, 0, 0.3)"
                  _hover={{
                    bg: 'primary',
                    color: 'white',
                  }}
                  w="100%"
                  color="white"
                  fontSize="0.875rem"
                >
                  Submit
                </Button>
              </VStack>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoginModal
