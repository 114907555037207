import { Box, IconButton } from '@chakra-ui/react'
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai'
import { motion } from 'framer-motion'

type IProps = {
  children: JSX.Element
  setCollapse: (param: boolean) => void
  isCollapsed: boolean
}

const Sidebar = ({ children, setCollapse, isCollapsed }: IProps) => {
  const { div: MotionDiv } = motion
  const collapasedAnimation = {
    marginLeft: '-310px',
  }

  const unCollapasedAnimation = {
    marginLeft: '0px',
  }

  const getAnimation = () => {
    if (isCollapsed) return collapasedAnimation

    return unCollapasedAnimation
  }

  return (
    <MotionDiv animate={getAnimation()} transition={{ duration: 0.5 }}>
      <Box
        width="340px"
        display={['none', 'none', 'block', 'block']}
        bg="white"
        px={'30px'}
        height="calc(100vh - 100px)"
      >
        <IconButton
          top="calc(50% - 12px)"
          position="relative"
          marginLeft="50px"
          right="-40px"
          aria-label="close sidebar"
          icon={
            isCollapsed ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />
          }
          size="xs"
          float="right"
          borderRadius="50%"
          onClick={() => setCollapse(!isCollapsed)}
        />
        <Box mt="1.5rem">{children}</Box>
      </Box>
    </MotionDiv>
  )
}

export default Sidebar
