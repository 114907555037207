import { useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

import FullWidthLayout from 'src/components/layouts/full-width'
import FeatureRequestForm from 'src/components/feature/feature-request-form'
import GridViewContent from 'src/components/views/grid-view-content'
import ListViewContent from 'src/components/views/list-view-content'
import { getToken } from 'src/api/get-token'
import { getRoadMap } from 'src/api/consumer'
import { AppContext } from 'src/contexts/appContext'
import type { URLTypes } from 'src/types/card'
import { setMetaDescription, setTitle } from 'src/utils/set-page-data'

const FeaturePage = () => {
  const { organisation, project }: URLTypes = useParams()
  const { updateOrgMetaData, setOrgData } = useContext(AppContext)

  useEffect(() => {
    const fetchData = async () => {
      const org = await getToken(organisation, project)
      localStorage.setItem('orm-token', org['token'])
      updateOrgMetaData(org)

      setTitle(`Open roadmap | ${org.project.name}`)
      setMetaDescription(`Roadmap for ${org.project.name}`)

      const fetchedCards = await getRoadMap()
      setOrgData({ ...fetchedCards })
    }

    fetchData()
  }, [])

  return (
    <FullWidthLayout
      sidebarContent={<FeatureRequestForm />}
      gridViewContent={<GridViewContent />}
      listViewContent={<ListViewContent />}
    />
  )
}

export default FeaturePage
