import { Text, Box } from '@chakra-ui/react'

type IProps = {
  title: string
  isActive: boolean
  stepCount: number
}

const StepTitle = ({ title, isActive, stepCount }: IProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box
        bg={isActive ? 'brand' : '#808080'}
        borderRadius="50%"
        color="white"
        h="28px"
        w="28px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {stepCount}
      </Box>
      <Text ml="0.75rem">{title}</Text>
    </Box>
  )
}

export default StepTitle
