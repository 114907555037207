import { useState, useEffect, useContext } from 'react'
import {
  Box,
  Text,
  Button,
  VStack,
  Divider,
  Input,
  Link,
} from '@chakra-ui/react'
import { FcGoogle } from 'react-icons/fc'
import { VscGithub } from 'react-icons/vsc'
import { useHistory } from 'react-router-dom'

import { googleSignin, githubSignin } from 'src/firebase/firebase'
import { AppContext } from 'src/contexts/appContext'
import { isValidUser } from 'src/utils/is-valid-user'

type IProps = {
  location?: string
}

const Login = ({ location }: IProps) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const { user } = useContext(AppContext)
  const isLoggedIn = isValidUser(user)

  useEffect(() => {
    if (user.doneFetching && isLoggedIn) {
      history.push('/projects')
    }
  }, [user])

  const handleSubmit = () => {
    console.log('button clicked')
  }

  const redirect = async (login: () => void) => {
    if (!isLoggedIn) {
      await login()
      if (location == 'page' && isLoggedIn) {
        history.push('/projects')
      }
    }
  }

  const renderUI = () => {
    return (
      <Box
        bg='url("assets/login-background.png")'
        height="100vh"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
      >
        <Box
          display="flex"
          height="inherit"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            bg="white"
            padding={['1.25rem', '1.25rem', '3.25rem', '3.25rem']}
            borderRadius="30px"
            width={['20.875rem', '20.875rem', '25.625rem', '25.625rem']}
          >
            <Box display="flex">
              <Text fontSize="18px">
                <Box as="img" display="inline-block" src="assets/logo.png" />
                pen Roadmap
              </Text>
            </Box>
            <Text
              mt={['1.25rem', '1.25rem', '1.875rem', '1.875rem']}
              fontWeight="600"
              fontSize={['1.375rem', '1.375rem', '1.5rem', '1.5rem']}
            >
              Login to your
            </Text>
            <Text
              fontWeight="600"
              fontSize={['1.375rem', '1.375rem', '1.5rem', '1.5rem']}
            >
              Open Roadmap Account
            </Text>
            <VStack spacing="1rem" mt="1.25rem">
              <Button
                width="100%"
                tabIndex={-1}
                _hover={{
                  bg: '#F7F7F7',
                }}
                fontSize="0.875rem"
                bg="#F7F7F7"
                size="lg"
                onClick={() => redirect(googleSignin)}
                leftIcon={<FcGoogle />}
              >
                Continue With Google
              </Button>
              <Button
                tabIndex={-1}
                width="100%"
                onClick={() => redirect(githubSignin)}
                fontSize="0.875rem"
                _hover={{
                  bg: 'black',
                }}
                bg="black"
                size="lg"
                color="white"
                leftIcon={<VscGithub />}
              >
                Continue With GitHub
              </Button>
            </VStack>
            <Box
              mt="30px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Divider />
              <Text px="0.5rem" fontSize="14px">
                Or
              </Text>
              <Divider />
            </Box>
            <Box mt="1.875rem">
              <VStack spacing="1rem" mt="1.25rem">
                <Input
                  type="text"
                  fontSize="0.875rem"
                  size="lg"
                  placeholder="E-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  type="password"
                  fontSize="0.875rem"
                  size="lg"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  onClick={handleSubmit}
                  size="lg"
                  type="submit"
                  background="rgba(0, 0, 0, 0.3)"
                  _hover={{
                    bg: 'primary',
                    color: 'white',
                  }}
                  w="100%"
                  color="white"
                  fontSize="0.875rem"
                >
                  Submit
                </Button>
                <Text
                  textAlign="center"
                  color="rgba(0, 0, 0, 0.3);"
                  mt="15px"
                  fontSize="1rem"
                >
                  Don’t have an account?
                  <Link
                    tabIndex={-1}
                    textDecoration="underline"
                    href={`${window.location.origin}/register`}
                  >
                    Sign Up
                  </Link>
                </Text>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return user.doneFetching ? renderUI() : <></>
}

export default Login
