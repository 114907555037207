import { useState } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { AiOutlinePlus } from 'react-icons/ai'

import Panel from 'src/components/top-bar'
import CreateNewProject from 'src/components/projects/create-new-project'

const Projects = () => {
  const [isCreateProjectVisibile, setCreateProjectVisibility] = useState(false)

  const renderComponent = () => {
    if (isCreateProjectVisibile) {
      return <CreateNewProject />
    }

    return (
      <>
        <Text
          fontWeight={700}
          fontSize={['1.875rem', '1.875rem', '2.5rem', '2.5rem']}
        >
          Quoppo
        </Text>
        <Box
          onClick={() => setCreateProjectVisibility(true)}
          role="button"
          mt="3.125rem"
          cursor="pointer"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={['17.75rem', '17.75rem', '24.125rem', '24.125rem']}
          width={['20.9rem', '20.9rem', '22.5rem', '22.5rem']}
          bg="#F7F7F7"
          borderRadius="20px"
          border="1px dashed rgba(0, 0, 0, 0.3)"
        >
          <Text display="flex" alignItems="center">
            <AiOutlinePlus /> &nbsp; Create New Project
          </Text>
        </Box>
      </>
    )
  }

  return (
    <>
      <Panel type="projects" />
      <Box
        pt={['1.875rem', '1.875rem', '3.6rem', '3.6rem']}
        px={['1.25rem', '1.25rem', '4.685rem', '4.685rem']}
        pb="4.75rem"
      >
        {renderComponent()}
      </Box>
    </>
  )
}

export default Projects
